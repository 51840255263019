import { create } from '@storybook/theming'

export default create({
  base: 'light',
  brandTitle: 'Kaya UI',
  brandUrl: 'https://kaya.io',
  brandImage:
    'https://res.cloudinary.com/be-rainbow/image/upload/v1667150225/Logo_ce7eaa9a26.png',
  brandTarget: '_self',
})
